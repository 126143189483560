import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Recruit = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="採用情報｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-recruitPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Recruit</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <AnchorLink to="#message" className=''>
                社長からのメッセージ
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#RecruitmentType" className=''>
                募集職種
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#WorkingWelfare" className=''>
                働き方・福利厚生
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#FAQ" className=''>
                採用FAQ
              </AnchorLink>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="PageDescription" className="w-full mb-10">
              <h2 className="font-Copperplate text-center text-3xl sm:text-6xl py-5">
                <span className=" text-key-sand ">Crazy</span> and Professional
              </h2>
              <p className="text-center text-2xl mb-20">
                遊びも仕事も全力で取り組むエンジニア集団です
              </p>
            </section>

            <section id="message" className="w-full mb-10">
              <div className="md:flex">
                <div className="mb-10">
                  <h3 className="frame-box text-xl lg:text-2xl">
                    新しい技術に興味関心があり、自らキャッチアップしていける仲間を探しています。
                  </h3>
                  <p>
                    弊社は2012年設立。<br />
                    「WEBシステムの受託開発」と「自社サービスの提供」を事業として展開しています。<br />
                    <br />
                    スタートアッププラスの強味は、少数精鋭で豊富な知識・経験があるスタッフが揃っていること。<br />
                    多くの経験・ノウハウを基にクライアントにとってもっとも価値の高いシステム開発を目指しています。<br />
                    <br />
                    社内での受託開発をメインとして、大手サービス企業、調査、教育、医療介護、官公庁等、幅広い業界のシステム開発に携わってきました。<br />
                    要件定義・開発・納品・保守までを一貫して行うケースが多く、WEBシステムの開発に関する様々なナレッジが得られます。<br />
                    <br />
                    メンバーは最新技術に敏感で、勉強会や開発合宿、検証機の導入、社内チャットでは技術的な話題で盛り上がったりと、
                    エンジニアがスキルアップし続けられる機会を多数設けています。<br />
                    <br />
                    また、エンジニアが働きやすい環境を大切にしており、有給取得率は60%以上、ワークライフバランスの整った職場環境で自分のペースで業務に携わることができます。
                  </p>
                </div>
                <div className="ml-auto md:ml-10 flex flex-col items-center ">
                  <StaticImage src="../images/kuwayama-message.png" className="w-72"/>
                  <p className="text-center mt-2">
                    代表取締役　桒山 雄介
                  </p>
                </div>
              </div>
            </section>

            <section className="mb-20">
              <ul className="md:flex justify-evenly ">
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-data" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    DATA
                    </h3>
                    <p className="text-center mb-5">
                      データで見る<br />スタートアッププラス
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-environment" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    ENVIRONMENT
                    </h3>
                    <p className="text-center mb-5">
                    働く環境
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-members" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    MEMBERS
                    </h3>
                    <p className="text-center mb-5">
                    メンバー紹介
                    </p>
                  </Link>
                </li>
              </ul>
            </section>

            <section id="RecruitmentType" className="mb-20">
              <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-5 py-3 ">
                募集職種
              </h2>
              <ul className="list-disc list-inside text-lg sm:text-xl">

                <li className="text-key-sand m-4">
                  <Link to="/requirement/engineer" className=''>
                    <span className="text-neutral-800">ソフトウェアエンジニア（経験者）</span>
                  </Link>
                </li>
                <li className="text-key-sand m-4">
                  <Link to="/requirement/engineer-beginner" className=''>
                    <span className="text-neutral-800">ソフトウェアエンジニア（未経験可）</span>
                  </Link>
                </li>
              </ul>
            </section>

            <section id="WorkingWelfare" className="mb-20">
              <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-5 py-3 ">
                働き方・福利厚生
              </h2>
              <div className="md:flex flex-wrap justify-between">
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                    フルフレックスタイム制度
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon1.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5">
                    コアタイムなしのフルフレックス制を導入しています。集中して最大のアウトプットを出せるよう、自分にあった働き方をすることができます。<br />
                    <small>※データ分析の職種を除く</small><br />
                    <small>※入社1年未満はコアタイム有</small>
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  リモートワーク制度
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon2.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    勤務場所に柔軟性を持たせ、多様な働き方に対応し、社員がその能力を継続して十分に発揮できる職場環境を作る目的としてリモートワーク制度を導入しています。<br />
                    <small>※データ分析の職種を除く</small>
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  スキルアップ補助制度
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon3.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5">
                    社員のスキルアップを積極的に応援しており、業務に関係する資格に合格した際には、受験料全額を会社が負担します。<br />
                    また、業務に必要な技術を習得するための参考書や技術書籍等を、会社負担で購入することができます。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  ホームオフィスサポート
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon4.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    在宅での業務中にかかる光熱費や通信費をサポートするため、在宅勤務をした日数に応じて、毎月￥1,000〜￥3,000を補助しています。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  フリードリンク・ウォーターサーバー
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon5.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    スタッフがリラックスして仕事に集中できるよう、オフィス内はフリードリンクになっています。<br />
                    ミネラルウォーター、コーヒー、ラテ、緑茶などを取り揃えています。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  社員旅行
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon6.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 ">
                    社員旅行を行っており、行き先は社員全員の話合いや多数決で決めています。<br />
                    これまで海外では台湾/セブ島/サンフランシスコ/ケアンズ/バリ、国内では那須高原/熱海/伊豆/安比高原/北海道/大阪などに行きました。<br />
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  慶弔休暇、慶弔金
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon7.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    慶事・弔事がある際には、慶弔休暇を取得することができます。<br />
                    また、結婚祝金や出産祝金、弔慰金などの支給があります。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  出産・育児・介護支援
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon8.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 ">
                    出産・育児・介護と仕事との両立支援を目的に様々な制度を用意しています。（産前産後休暇、育児休業、配偶者出産休暇、介護休業、短時間勤務制度等）
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  夏季・年末年始休暇
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon9.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    夏季休暇：7〜9月中に3日間の特別有給休暇を付与しています。<br />
                    <br />
                    年末年始休暇：12月29日〜1月3日（休日の絡み等で変動あり）
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  オフィス内薬箱
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon10.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 ">
                    市販の頭痛薬や胃腸薬等が入った薬箱が社内に設置されており、体調不良時には無料で利用することができます。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  インフルエンザ予防接種費用負担
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon11.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 pt-3">
                    インフルエンザの予防接種を受けた社員に対して、接種費用を全額支給しています。
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-5">
                  <h3 className="text-xl md:text-xl border-l-[5px] border-key-sand mt-5 mb-3 py-2 pl-3">
                  定期健康診断
                  </h3>
                  <div className="flex justify-between items-center">
                    <StaticImage src="../images/welfareIcon12.png" className=" w-1/4 h-auto"/>
                    <p className="w-3/4 pl-5 ">
                    社会保険に加入しているスタッフを対象に、年1回年齢に応じた定期健康診断を全額会社負担で実施しています。女性は乳がんや子宮頸がんの婦人科検診も会社負担で受診することができます。
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="FAQ" className="mb-20">
              <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-5 py-3 ">
                採用FAQ
              </h2>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  選考フローを教えてください。
                </summary>
                <p className="p-5 border-l border-key-sand">
                  職種によって異なりますので、応募される職種の募集要項ページにてご確認ください。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  在職中のため、平日の夕方以降もしくは土日の面接は可能ですか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                  面接の日程につきましては、できる限り考慮させていただいております。<br />
                  土日祝日の面接は基本的に行っておりませんが、随時ご相談ください。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  オンライン面接は可能ですか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                  募集職種によって実施方法が異なりますが、エンジニアの職種ではオンライン面接が可能です。<br />
                  ただし、適性検査や筆記テストなど、面接の内容に応じてご来社いただく場合がございます。<br />
                  その他の職種につきましてはご相談ください。<br />
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  外国籍でも応募は可能ですか？<br />
                </summary>
                <p className="p-5 border-l border-key-sand">
                  スタートアッププラスでは国籍は問いません。<br />
                  日本語でのコミュニケーションに問題がなければ、積極的にご応募いただきたいと思っております。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  選考における交通費は支給されますか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                最終選考時、遠方からご来社いただく場合は支給いたします。<br />
                最終選考以外については原則自己負担とさせていただきます。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  書類選考・面接試験の結果はどのように伝えられるのでしょうか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                  書類選考及び試験結果は合否に関係なく、書面・メールもしくはお電話にて必ずご連絡致します。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  履歴書などの応募書類は返してもらえますか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                  ご要望があれば返却致します。<br />
                  特に申し出がなければ、当社の個人情報の廃棄手順に則り廃棄いたします。<br />
                  社内での取り扱いについては社内規定、及び個人情報保護法に則り、厳重に管理しております。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  面接時、社内見学は可能ですか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                  可能です。ご希望があれば面接時にお申し出ください。
                </p>
              </details>
              <details className="my-2">
                <summary className="py-2 list-none flex items-center border-b border-dashed border-key-sand cursor-pointer">
                  <StaticImage src="../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                  試用期間はありますか？
                </summary>
                <p className="p-5 border-l border-key-sand">
                入社日より6ヶ月間を試用期間として設けています。<br />
                ただし、ご経験や試用期間中の評価等により、試用期間の短縮・延長、あるいは設けないことがあります。
                </p>
              </details>

            </section>

            <section className="mb-20">
              <ul className="md:flex justify-evenly ">
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-data" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    DATA
                    </h3>
                    <p className="text-center mb-5">
                      データで見る<br />スタートアッププラス
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-environment" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    ENVIRONMENT
                    </h3>
                    <p className="text-center mb-5">
                    働く環境
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-members" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    MEMBERS
                    </h3>
                    <p className="text-center mb-5">
                    メンバー紹介
                    </p>
                  </Link>
                </li>
              </ul>
            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Recruit

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

